import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: resolve => require(['../views/Home.vue'],resolve),
  },
  {
    path: '/servicecenter',
    name: '服务中心',
    component: resolve => require(['../views/ServiceCenter.vue'],resolve),
  },
  {
    path: '/caseshow',
    name: '项目案例',
    component: resolve => require(['../views/CaseShow.vue'],resolve),
  },
  {
    path: '/products',
    name: '六大产品线',
    component: resolve => require(['../views/CaseShow/sixProductLines.vue'],resolve),
  },
  {
    path: '/jellyplanet',
    name: '果冻星球',
    component: resolve => require(['../views/CaseShow/jellyPlanet.vue'],resolve),
  },
  {
    path: '/resources',
    name: '演艺资源',
    component: resolve => require(['../views/CaseShow/resources.vue'],resolve),
  },
  {
    path: '/ourproduct',
    name: '我们的产品',
    component: resolve => require(['../views/OurProducts.vue'],resolve),
  },
  {
    path: '/aboutus',
    name: '关于我们',
    component: resolve => require(['../views/AboutUs.vue'],resolve),
  },
  {
    path: '/cooperationnegotiate',
    name: '合作洽谈',
    component: resolve => require(['../views/CooperationNegotiate.vue'],resolve),
  },
  {
    path: '/serviceagreement',
    name: '服务协议',
    component: resolve => require(['../views/ServiceAgreement.vue'],resolve),
  },
  {
    path: '/privacypolicy',
    name: '隐私政策',
    component: resolve => require(['../views/PrivacyPolicy.vue'],resolve),
  },
  {
    path: '/legalnotices',
    name: '法律声明',
    component: resolve => require(['../views/LegalNotices.vue'],resolve),
  },
  {
    path: '/ruleofsunshine',
    name: '阳光准则',
    component: resolve => require(['../views/RuleOfSunshine.vue'],resolve),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// // 在路由跳转前进行判断
// router.beforeEach((to, from, next) => {
//   if (to.matched.length === 0) {
//     // 如果目标路由不存在，重定向到首页
//     next({ path: '/' });
//   } else {
//     // 继续路由跳转
//     next();
//   }
// });
 
export default router;
