<template>
  <div id="app">
    <div class="navigation_bar_spare" ref="navigation_bar">
      <div>
        <img @click="home" style="cursor:pointer;" src="https://img.youstarplanet.com/website/logo.svg" alt="">
        <ul>
          <li><router-link to="/">首页</router-link></li>
          <li><router-link to="/servicecenter">服务中心</router-link></li>
          <!-- <li><router-link to="/ourproduct">我们的产品</router-link></li> -->
          <li><router-link to="/aboutus">关于我们</router-link></li>
        </ul>
      </div>
    </div>
    <div style="height: 90px;"></div>
    <router-view class="content"></router-view>
    <div class="base">
      <div>
        <div class="div_left">
          <ul>
            <li>
              <span>联系我们</span>
              <router-link to="/cooperationnegotiate">合作洽谈</router-link>
            </li>
            <li>
              <span>法律信息</span>
              <router-link to="/serviceagreement">服务协议</router-link>
              <router-link to="/privacypolicy">隐私政策</router-link>
            </li>
            <li>
              <span>关注我们</span>
              <el-popover
                placement="bottom-start"
                width="175"
                trigger="hover">
                <div>
                  <img style="width: 150px;" src="./assets/erweim.jpeg" alt="">
                </div>
                <img class="wx_img" slot="reference" src="https://img.youstarplanet.com/website/wx.svg" alt="">
              </el-popover>
            </li>
          </ul>
          <p>
            <router-link to="/legalnotices">法律声明</router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <router-link to="/ruleofsunshine">阳光准则</router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://beian.miit.gov.cn" target="_blank"> 粤ICP备2021057775号</a>&nbsp;|
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602006653" target="_blank">
              <img src="https://img.youstarplanet.com/website/bottom_logo1.png" alt="">
              粤公网安备44030602006653号
            </a>
          </p>
        </div>
        <div class="div_right" style="cursor:pointer;">
          <img  @click="home" src="https://img.youstarplanet.com/website/bottom_logo.svg" alt="">
          <p>Copyright © 2021 - 2022 You-Star-Planet Art-Tech Service Co.,Ltd. All Rights Reserved. 星都时代 版权所有</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default{
  data() {
    return {
      scroll:'',
      scrollToped:'',//上一个滚动事件
      scrollTopeds:'',
    }
  },
  created(){ 
    let mhost = 'https://m.youstarplanet.com'
    let host = 'https://www.youstarplanet.com'

    // 判定是手机打开还是pc
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      if (location.origin != mhost) {
        window.location.href = mhost + location.pathname;
      }
      // console.log('移动端');
    } else {
      if (location.origin != host) {
        window.location.href = host + location.pathname;
      }
      // console.log('PC端');
    }
  },
  methods: {
    home(){
      this.$router.push({path:`/`})
    },
    handleScroll(){
      this.scrollTopeds = this.scrollToped
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      this.scrollToped = scrollTop
      // console.log(this.scrollTopeds,'过去','===',scrollTop,'当前');
      if (scrollTop >= 130) {
        this.$refs.navigation_bar.style.top = "-90px"//下滑
      }
      if (this.scrollTopeds > scrollTop) {
        this.$refs.navigation_bar.style.top = "0px"//上滑
      }
    }
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll,true)
  }
}
</script>

<style lang="less">
*{
    margin: 0;
    padding: 0;
  }
  a, ul, li, div, p{
    color: #101010;
    box-sizing: border-box;
  }
  ul{
    list-style: none;
  }
  a{
    text-decoration: none;
  }
#app{
  //上滑//下滑
  .navigation_bar_spare{
    background-color: #FFFFFF;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    top: 0px;
    transition: all 0.5s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    &>div{
      width: 1270px;
      height: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &>img{
        object-fit:none;
      }
      &>ul{
        display: flex;
        li{
          font-size: 18px;
          margin-left: 60px;
          font-size: 16px;
          a{
            color: #515151;
          }
          &:hover{
            font-weight: bold;
          }
          &:nth-child(1){
            margin-left: 0;
          }
          .router-link-active{
            color: #000000;
            font-weight: bold;
          }
        }
      }
    }
  }
  .base{
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    &>div{
      width: 1270px;
      height: 400px;
      margin: 0 auto;
      padding: 60px 0 50px;
      display: flex;
      justify-content: space-between;
      .div_left{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        ul{
          display: flex;
          li{
            display: flex;
            flex-direction: column;
            margin-left: 100px;
            &:nth-child(1){
              margin-left: 0;
            }
            a{
              font-size: 16px;
              margin-bottom: 10px;
              color: #5f6464;
            }
            span{
              font-size: 18px;
              color: #5f6464;
              font-weight: bold;
              margin-bottom: 27px;
            }
            .wx_img{
              width: 18px;
              object-fit:cover;
              margin-left: 10px;
              margin-right: 8px;
            }
          }
        }
        p{
          color: #5f6464;
          display: flex;
          a{
            color: #5f6464;
            display: flex;
            align-content: center;
            img{
              width: 18px;
              object-fit:cover;
              margin-left: 10px;
              margin-right: 8px;
            }
          }
        }
      }
      .div_right{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 14px;
        img{
          object-fit:cover;
          width: 163px;
          height: 86px;
        }
        p{
          color: #5f6464;
          margin-bottom: 1px;
        }
      }
    }
  }
}
</style>
